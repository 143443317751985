html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.PhoneInputInput {
  margin-bottom: 0;
  border-color: white;
}

.loader-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

.loader-content {
  text-align: center;
}

.forgotpassword-container {
  color: #447969;
  text-decoration: underline;
  cursor: pointer;
}

.forgotpassword-container:hover {
  text-decoration: none;
  color: #447969;
}
.btn-primary.disabled,
.btn-primary:disabled {
  background-color: grey !important;
  color: white !important;
  border-color: grey !important;
}

.btn-primary:disabled:hover {
  background-color: grey !important;
  border-color: grey !important;
}
.remove_card_btn{
  font-size: 15px;
    text-decoration: underline;
    background: none;
    border: none;

}
.remove_card_btn:hover, .remove_card_btn:focus {
  outline: none;
}

.freshchat-widget {
  bottom: 70px !important;
}